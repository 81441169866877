import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './user/user';
import global from './global/global';


export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    global
  }
})
