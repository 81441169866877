import axios from 'axios'

import { api } from '../../api.js';

/* ==================================== */
/* ============== add ============== */
/* ==================================== */

export const addUserAction = ({ rootGetters }, vals) => {
    const url = api.user.add
    return axios.post(url, {
            'login':  vals.login,
            'password': vals.password,
            'email': vals.email,
            'review': vals.review,
            'review_annuaire': vals.review_annuaire,
            'cab': vals.cab
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

/* ==================================== */
/* ============== edit ============== */
/* ==================================== */

export const editUserAction = ({ rootGetters }, vals) => {
    const url = api.user.edit + vals.user
    const params = {
        'login':  vals.login,
        'email': vals.email,
        'review': vals.review,
        'review_annuaire': vals.review_annuaire,
        'cab': vals.cab
    }

    if (vals.password.length > 0) {
        params['password'] = vals.password
    }

    return axios.put(url,
        params,
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}


/* ==================================== */
/* ============== delete ============== */
/* ==================================== */

export const deleteUserAction = ({ rootGetters }, userId) => {
    const url = api.user.delete + userId
    return axios.delete(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}


/* ==================================== */
/* ============== Search ============== */
/* ==================================== */

export const searchUserAction = ({ rootGetters },query) => {
    const url = api.user.search
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        },
        params: {
            search: query
        }
    })
}

/* ==================================== */
/* ============== get ================= */
/* ==================================== */

export const getUserAction = ({ rootGetters }, userId) => {
    const url = api.user.get + userId
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}


/* ==================================== */
/* ============== log ================= */
/* ==================================== */

export const getUserLogAction = ({ rootGetters }, userId) => {
    const url = api.user.log + userId + '/log'
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}