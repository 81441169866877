import Vue from 'vue'
import VueRouter from 'vue-router'
import storeGlobal from '../store/global/global';
import { utils } from '../utils.js'

function checkAuth(to, from, next) {
  const token = localStorage.getItem('token');
  const tokenIsOk = utils.auth.checkToken(token);

  if(tokenIsOk) {
      storeGlobal.state.errors = [];
      storeGlobal.state.isLog = true;
      storeGlobal.state.token = token;
      next()        
  } else {
      next('/login')
  }
}

Vue.use(VueRouter)

const routes = [
  {
        path: '/',
        redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/user/:userId',
    name: 'user',
    component: () => import('../views/User.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/add-user',
    name: 'userAdd',
    component: () => import('../views/UserAdd.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/user/:userId/edit',
    name: 'userEdit',
    component: () => import('../views/UserEdit.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/user/:userId/log',
    name: 'userLog',
    component: () => import('../views/UserLog.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/user/:userId/edit-password',
    name: 'userEditPassword',
    component: () => import('../views/UserEditPassword.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/site/:siteId/wp-article',
    name: 'site',
    component: () => import('../views/ArticlesWp.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/article/:articleId',
    name: 'articleEdit',
    component: () => import('../views/Article.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    },
    props: true
  },
  {
    path: '/article/:articleId/send-wp',
    name: 'articleSendWp',
    component: () => import('../views/ArticleSendWp.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    },
    props: true
  },
  {
    path: '/user/:userId/add-article',
    name: 'articleAdd',
    component: () => import('../views/Article.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    },
    props: true
  },
  {
    path: '/annuaire-inscription/:annuaireInscriptionId',
    name: 'annuaireInscriptionEdit',
    component: () => import('../views/AnnuaireInscription.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    },
    props: true
  },
  {
    path: '/annuaire-inscription/:annuaireInscriptionId/send',
    name: 'annuaireInscriptionSend',
    component: () => import('../views/AnnuaireInscriptionSend.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    },
    props: true
  },
  {
    path: '/user/:userId/add-annuaire-inscription',
    name: 'annuaireInscriptionAdd',
    component: () => import('../views/AnnuaireInscription.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    },
    props: true
  },
  {
    path: '/annuaire',
    name: 'annuaire',
    component: () => import('../views/Annuaire.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/annuaire/add',
    name: 'annuaireAdd',
    component: () => import('../views/AnnuaireAdd.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/annuaire/:annuaireId',
    name: 'annuaireEdit',
    component: () => import('../views/AnnuaireEdit.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/theme',
    name: 'theme',
    component: () => import('../views/Theme.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/theme/add',
    name: 'themeAdd',
    component: () => import('../views/ThemeAdd.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  },
  {
    path: '/theme/:themeId',
    name: 'themeEdit',
    component: () => import('../views/ThemeEdit.vue'),
    beforeEnter : (to, from, next) => {
        checkAuth(to, from, next);
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
