<template>
    <div class="flash">
        <div class="flash-item error" v-if="errors.length != 0">
            <div class="flash-item-layout" v-for="x in errors" v-bind:key="x.id">
                {{x.msg}}
                <v-icon color="blue lighten-3" @click="removeError(x.id)" title="Fermer">
                    mdi-close-circle-outline
                </v-icon>
                <span v-if="typeof x.code !== 'undefined' && x.code.length > 0">
                    <br><small>(code : {{x.code}})</small>
                </span>
            </div>
        </div>
        <div class="flash-item success" v-if="success.length != 0">
            <div class="flash-item-layout" v-for="x in success" v-bind:key="x.id">
                {{x.msg}}
                <v-icon color="blue lighten-3" @click="removeSuccess(x.id)" title="Fermer">
                    mdi-close-circle-outline
                </v-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Flash',
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters("global", [
            "getErrors",
            "getSuccess"
        ]),
        errors: {
            get() {
                this.makeSetTimeError(this.getErrors.map(x => x.id))
                return this.getErrors;
            }
        },
        success: {
            get() {
                this.makeSetTimeSuccess(this.getSuccess.map(x => x.id))
                return this.getSuccess;
            }
        },
    },
    methods: {
        ...mapMutations("global", [
            "removeError",
            "removeSuccess"
        ]),
        makeSetTimeError(ids) {
            for (let i = 0; i < ids.length; i++) {
                const _this = this;
                setTimeout(function() {
                    _this.removeError(ids[i])
                }, 7000);
            }
        },
        makeSetTimeSuccess(ids) {
            for (let i = 0; i < ids.length; i++) {
                const _this = this;
                setTimeout(function() {
                    _this.removeSuccess(ids[i])
                }, 7000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.flash
{   
    .flash-item
    {
        z-index: 0;
        position: fixed;
        bottom:50px;
        width: 1000px;
        left: 0px;
        right: 0px;
        margin: auto;

        .flash-item-layout 
        {
            padding:20px;          
        }

        &.error .flash-item-layout
        {
            color:#fff !important;
            background-color: #d62828;
            border-top: 1px solid #d62828
        }

        &.error .flash-item-layout button
        {
            float: right;
            color:#fff !important;
        }

        &.success .flash-item-layout
        {
            color:#fff !important;
            background-color: #4caf50;
            border-top: 1px solid #4caf50;
        }

        &.success .flash-item-layout button
        {
            bottom: 5px;
            float: right;
            color:#fff !important;
        }
    }
}
</style>