<template>
    <v-app>
        <v-navigation-drawer
          v-if="getIsLog"
          v-model="drawer"
          fixed
          app
          clipped
        >
          <v-list
            nav
            dense
          >
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <v-list-item  @click="$router.push({path: '/annuaire'})">
                <v-icon>mdi-book-open</v-icon> 
                <v-list-item-title>Annuaires</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({path: '/theme'})">
                <v-icon>mdi-shape</v-icon> 
                <v-list-item-title>Thèmes</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item>
                <v-icon>mdi-format-list-bulleted</v-icon> 
                <v-list-item-title>Logs</v-list-item-title>
              </v-list-item> -->
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <v-app-bar class="top__bar" color="elevation-4" flat dark fixed app clipped-left>
            <v-app-bar-nav-icon v-if="getIsLog" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <router-link to="/">
                <v-toolbar-title>Administration Maax</v-toolbar-title>
            </router-link>
            <search v-if="getIsLog"></search>
            <v-spacer></v-spacer>
            <v-btn v-if="getIsLog" @click="$router.push({path: '/add-user'})">
                <v-icon>mdi-account-plus</v-icon>
                &nbsp;Ajouter un utilisateur
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="getIsLog" icon @click="logout()">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        <main>
            <router-view />
        </main>
        <flash></flash>
    </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import fr from '../node_modules/vuetify/src/locale/fr.ts'
import search from './components/static/search.vue'
import flash from './components/static/flash.vue';

export default {
    name: 'App',
    data() {
        return {
            drawer: false,
            group: null
        }
    },
    components: {
        search,
        flash
    },
    created: function () {
        this.$vuetify.lang.locales = { fr }
        this.$vuetify.lang.current = 'fr'
    },
    computed: {
      ...mapGetters("global", [
            "getIsLog"
      ]),
    },
    watch: {
      group () {
        this.drawer = false
      }
    },
    methods : {
        ...mapMutations("global", [
            "setToken",
            "setUser",
            "setPass",
            "setAutorisations",
            "setIsLog"
        ]),
        ...mapActions("global", [
            "getTokenConnect"
        ]),
        logout : function () {
            this.setIsLog(false);
            localStorage.removeItem("token");
            this.setToken("");
            this.setUser("");
            this.setPass("");
            this.setAutorisations([]);
            this.$router.push('/login');
        }
    }
};
</script>

<style lang="scss">
    a {
        text-decoration: none;
        color: white !important;
    }
    main {
        padding-top: 4%;
    }
</style>