import jwt_decode from "jwt-decode";
import moment from 'moment'
import 'moment-timezone';

export const utils = {
	auth : {
		checkToken : token => {
			if(token == null) {
				return false
			}

			try {
				const tokenData = jwt_decode(token);
				const tokenIsValide = tokenData != null ? (tokenData.exp * 1000) > new Date().getTime() : false;
				return tokenIsValide
			} catch(e) {
				console.log(e);
				return false
			}
		},
		getIdUser : token => {
			try {
				const tokenData = jwt_decode(token);
				return tokenData.id
			} catch(e) {
				return false
			}
		}
	},
	ajax : {
		normalize_error : err => {
			const response = err.response;
			const now = new Date();
			const normalize = {
				date : now,
				timestamp : now.getTime(),
				msg : "inconnu",
				code : "inconnu",
				detail : []				
			}

			if(response == undefined) {
				normalize["msg"] = "Erreur de réseaux lors de la requete";
				normalize["code"] = "network_error";
				normalize["detail"] = [err.stack];
				return normalize;
			}

			if(!("data" in response)) {
				normalize["msg"] = "Erreur lors de la requete";
				normalize["code"] = "query_error_not_normalized";
				normalize["detail"] = [err.stack];
				return normalize;
			}

			if(typeof response.data != 'object') {
				normalize["msg"] = "Erreur lors de la requete";
				normalize["code"] = "query_error_not_normalized";
				normalize["detail"] = [err.stack];
				return normalize;
			}

			normalize["msg"] = ("msg" in response.data) ? response.data.msg : "Erreur inconnu";
			normalize["code"] = ("code" in response.data) ? response.data.code : "code_inconnu";
			normalize["detail"] = ("detail" in response.data) ? response.data.detail : [];
			return normalize;
		}
	},
	tools : {
		formatedDate(date, format="DD/MM/YYYY à HH:mm") {
			return moment.utc(String(date)).tz('Europe/Paris').format(format)
		}
	}
}
