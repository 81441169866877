export default {
    getToken						: state => state.token,
    getIsLog						: state => state.isLog,
    getAutorisations				: state => state.autorisations,
    aut								: state => state.autorisations,
    getUser                    	 	: state => state.user,
    getPass                     	: state => state.pass,
    getLoginErrors            	 	: state => state.loginErrors,
    getAjaxRun                		: state => state.ajaxRun,
    getErrors                       : state => state.errors,
    getErrorsIds                    : state => state.errors.map(x => x.id),
    getSuccess                      : state => state.success
}