import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {api} from '../../api.js';
import _ from 'lodash'; 

/* ==================================== */
/* ============== token =============== */
/* ==================================== */

export const getTokenConnect = ({ commit, getters }) => {
    var user = getters.getUser;
    var pass = getters.getPass;

    commit("setAjaxRun", true);
    return axios.get(api.adminer.getToken + user + "/" + pass)
    .then(function (response) {
        var decoded = jwtDecode(response.data);
        var droits = JSON.parse(decoded.droit);
        var droitsLogevent = droits.filter(function (x) {
            return x.service == "MAAX";
        });

        commit("setAjaxRun", false);
        
        if(droitsLogevent.length == 0)
        {
            commit("setLoginErrors", ["Vous n'avez pas accès à ce service."]);
            return;
        }

        commit("setLoginErrors", []);
        commit("setToken", response.data);
        commit("setIsLog", true);
        commit("setAutorisations", droitsLogevent[0].roles);
        localStorage.setItem('token',response.data);
    })
    .catch(function (error) {
        var response = error.response;
        commit("setAjaxRun", false);

        if(!("data" in response)) {
            commit("setLoginErrors", ["Erreur lors de la connexion"]);
            return;
        }

        if(typeof response.data != 'object' || !("erreur" in response.data)) {
            commit("setLoginErrors", ["Erreur lors de la connexion"]);
            return;
        }

        commit("setLoginErrors", [response.data.erreur]);
    }); 
}

/* ==================================== */
/* ============== annuaire ============ */
/* ==================================== */

export const getAnnuairesAction = ({ rootGetters }) => {
    const url = api.annuaire.getAll
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const getAnnuaireAction = ({ rootGetters }, annuaireId) => {
    const url = api.annuaire.get + annuaireId
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const addAnnuaireAction = ({ rootGetters }, vals) => {
    const url = api.annuaire.add
    return axios.post(url, {
            "name": vals.name,
            "content": vals.content,
            "url": vals.url,
            "login": vals.login,
            "password": vals.password,
            "theme": vals.theme,
            "logo": vals.logo
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const updateAnnuaireAction = ({ rootGetters }, vals) => {
    const url = api.annuaire.edit + vals.annuaire
    return axios.put(url, {
            "name": vals.name,
            "content": vals.content,
            "url": vals.url,
            "login": vals.login,
            "password": vals.password,
            "theme": vals.theme,
            "logo": vals.logo
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const deleteAnnuaireAction = ({ rootGetters }, annuaireId) => {
    const url = api.annuaire.delete + annuaireId
    return axios.delete(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

/* ================================================== */
/* ============== annuaire inscritpion ============== */
/* ================================================== */

export const getAnnuaireInscriptionAction = ({ rootGetters }, annuaireInscriptionId) => {
    const url = api.annuaireInscription.get + annuaireInscriptionId
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const addAnnuaireInscriptionAction = ({ rootGetters }, vals) => {
    const url = api.annuaireInscription.add
    const params = {
        'status': vals.status,
        'content': vals.content,
        'site': vals.site,
        'annuaire': vals.annuaire
    }

    if (vals.file > 0) {
        params['file'] = vals.file
    }

    return axios.post(url,
        params,
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const updateAnnuaireInscriptionAction = ({ rootGetters }, vals) => {
    const url = api.annuaireInscription.update + vals.annuaireInscription
    const params = {
        'status': vals.status,
        'content': vals.content
    }

    if (vals.file > 0) {
        params['file'] = vals.file
    }

    return axios.put(url,
        params,
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const deleteAnnuaireInscriptionAction = ({ rootGetters }, annuaireInscriptionId) => {
    const url = api.annuaireInscription.delete + annuaireInscriptionId
    return axios.delete(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const sendAnnuaireInscriptionAction = ({ rootGetters }, annuaireInscriptionId) => {
    const url = api.annuaireInscription.send + annuaireInscriptionId + '/send'
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

/* ===================================== */
/* ============== article ============== */
/* ===================================== */

export const getArticleAction = ({ rootGetters }, articleId) => {
    const url = api.article.get +  articleId
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const addArticleAction = ({ rootGetters }, vals) => {
    const url = api.article.add
    const params = {
        'titre':  vals.titre,
        'status': vals.status,
        'content': vals.content,
        'site': vals.site
    }

    if (vals.file > 0) {
        params['file'] = vals.file
    }

    return axios.post(url,
        params,
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const updateArticleAction = ({ rootGetters }, vals) => {
    const url = api.article.update + vals.article
    const params = {
        'titre':  vals.titre,
        'status': vals.status,
        'content': vals.content
    }

    if (vals.file > 0) {
        params['file'] = vals.file
    }

    return axios.put(url,
        params,
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const deleteArticleAction = ({ rootGetters }, articleId) => {
    const url = api.article.delete + articleId
    return axios.delete(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const sendArticleAction = ({ rootGetters }, articleId) => {
    const url = api.article.send + articleId + '/send'
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}


/* ===================================== */
/* ============== company ============== */
/* ===================================== */

export const addCompanyAction = ({ rootGetters }, vals) => {
    const url = api.company.add
    return axios.post(url, {
        "theme": vals.theme,
        "nom": vals.nom,
        "tel": vals.tel,
        "adr": vals.adr,
        "cp": vals.cp,
        "city": vals.city,
        "user": vals.user
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const updateCompanyAction = ({ rootGetters }, vals) => {
    const url = api.company.update + vals.company
    return axios.put(url, {
        "theme": vals.theme,
        "nom": vals.nom,
        "tel": vals.tel,
        "adr": vals.adr,
        "cp": vals.cp,
        "city": vals.city
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

/* ==================================== */
/* ============== file ================ */
/* ==================================== */

export const displayFileAction = ({ rootGetters }, fileId) => {
    return api.file.display + fileId + '/display?token=' +  rootGetters['global/getToken']
}

export const deleteFileAction = ({ rootGetters }, fileId) => {
    const url = api.file.delete + fileId
    return axios.delete(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const addFileAction = ({ rootGetters }, vals) => {
    const url = api.file.add + vals.user
    const formData = new FormData();
    formData.append('file', vals.uploadImage)
    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-token': rootGetters['global/getToken']
        }
    })
}

/* ==================================== */
/* ============== site ================ */
/* ==================================== */

export const getSiteAction = ({ rootGetters }, siteId) => {
    const url = api.site.get +  siteId
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const addSiteAction = ({ rootGetters }, vals) => {
    const url = api.site.add
    return axios.post(url, {
            "url": vals.url,
            "url_api": vals.url_api,
            "login": vals.login,
            "password": vals.password,
            "user": vals.user
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const updateSiteAction = ({ rootGetters }, vals) => {
    const url = api.site.update + vals.site
    return axios.put(url, {
            "url": vals.url,
            "url_api": vals.url_api,
            "login": vals.login,
            "password": vals.password
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const getSiteArticlesAction = ({ rootGetters }, siteId) => {
    const url = api.site.getArticles +  siteId + '/article'
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const getSiteArticlesWpAction = ({ rootGetters }, siteId) => {
    const url = api.site.getArticlesWP + siteId + '/wp-article'
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

/* ==================================== */
/* ============== theme =============== */
/* ==================================== */

export const getThemesAction = ({ rootGetters }) => {
    const url = api.theme.getAll
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
    .then(res => {
        res.data.sort(function (a, b) {
            var nameA = _.lowerCase(_.deburr(a.name));
            var nameB = _.lowerCase(_.deburr(b.name));
            return (nameA > nameB) ? 1 : -1;
        })

        return res
    })
}


export const addThemeAction = ({ rootGetters }, vals) => {
    const url = api.theme.add
    return axios.post(url, {
            "name": vals.name
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const updateThemeAction = ({ rootGetters }, vals) => {
    const url = api.theme.edit + vals.theme
    return axios.put(url, {
            "name": vals.name
        },
        {
            headers: { 
                'Content-Type': 'application/json',
                'x-token': rootGetters['global/getToken']
            }
        }
    )
}

export const deleteThemeAction = ({ rootGetters }, themeId) => {
    const url = api.theme.delete + themeId
    return axios.delete(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}

export const getThemeAction = ({ rootGetters }, themeId) => {
    const url = api.theme.get + themeId
    return axios.get(url, {
        headers: {
            'x-token': rootGetters['global/getToken']
        }
    })
}