export const mutations = {
  setSelectedUser: (state, vals) => {
    state.selectedUser = vals
  },
  setSearch: (state, vals) => {
    state.search = vals
  },
  setSearchResult: (state, vals) => {
    state.searchResult = vals
  }
}