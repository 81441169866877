// const url = "https://maax-api-preprod.nova-seo.com/";
const url = "https://maax-api-preprod.nova-seo.com/";
const url_adminer = "https://auth.nova-seo.com/";

export const api = {
  adminer: {
    getUrl: url_adminer,
    getToken: url_adminer + "api/token/"
  },
  user: {
    add:    url + 'user',
    edit:   url + 'user/',
    get:    url + 'user/',
    getAll: url + 'user',
    search: url + 'user',
    delete: url + 'user/',
    log:    url + 'user/'
  },
  site: {
    add: url + 'site',
    get: url + 'site/',
    update: url + 'site/',
    getArticles: url + 'site/',
    getArticlesWP: url + 'site/'
  },
  file: {
    add: url + 'file/',
    get: url + 'file/',
    delete: url + 'file/',
    display: url + 'file/'
  },
  company: {
    get: url + 'company/',
    add: url + 'company',
    update: url + 'company/'
  },
  article: {
    get: url + 'article/',
    add: url + 'article',
    delete: url + 'article/',
    update: url + 'article/',
    send: url + 'article/',
    uploadImage: url + 'article-upload-image',
    deleteImage: url + 'article-delete-image/',
    getAllImage: url + 'article-get-images'
  },
  annuaire: {
    get: url + 'annuaire/',
    add: url + 'annuaire',
    edit: url + 'annuaire/',
    getAll: url + 'annuaire',
    delete: url + 'annuaire/',
    sendTheme: url + 'annuaire-send-theme'
  },
  annuaireInscription: {
    add: url + 'annuaire-inscription',
    get: url + 'annuaire-inscription/',
    send: url + 'annuaire-inscription/',
    update: url + 'annuaire-inscription/',
    delete: url + 'annuaire-inscription/'
  },
  theme: {
    get: url + 'theme/',
    add: url + 'theme',
    edit: url + 'theme/',
    getAll: url + 'theme',
    delete: url + 'theme/'
  }
}