import * as actions from './actions';
import getters from './getters';
import {mutations} from './mutations';


const state = {
    token : "",
    isLog : false,
    user : "",
    pass : "",
    autorisations : [],
    success: [],
    errors : [],
    loginErrors: [],
    ajaxRun : false
}

export default {
	namespace: 'store/',
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}