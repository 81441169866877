export const mutations = {
	setToken: (state, vals) => {
		state.token = vals;			
	},
	setIsLog: (state, vals) => {
		state.isLog = vals;			
	},
	setAutorisations: (state, vals) => {
		state.autorisations = vals;
	},
	setUser: (state, vals) => {
		state.user = vals;			
	},
	setPass: (state, vals) => {
		state.pass = vals;			
	},
	setLoginErrors: (state, vals) => {
		state.loginErrors = vals;
	},
	resetLoginErrors: (state) => {
		state.loginErrors = [];
	},
	setAjaxRun: (state, vals) => {
		state.ajaxRun = vals;			
	},
	setErrors: (state, vals) => {
		state.errors = vals;
	},
	addErrors: (state, vals) => {
		vals.id = new Date().getTime();
		state.errors.push(vals);
	},
	removeError: (state, id) => {
		const i = state.errors.find(x => x.id == id);
		if(i == undefined) {
			return
		}

		state.errors.splice(i, 1);
	},
	resetErrors: (state) => {
		state.errors = [];
	},
	resetFlashMsg: (state) => {
		state.errors = [];
		state.success = [];
	},
	addSuccess: (state, vals) => {
		state.success.push({
			msg: vals,
			id: new Date().getTime()
		});
	},
	removeSuccess: (state, id) => {
		const i = state.success.find(x => x.id == id);
		if(i == undefined) {
			return
		}

		state.success.splice(i, 1);
	}
}