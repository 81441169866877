import * as actions from './actions';
import getters from './getters';
import {mutations} from './mutations';


const state = {
  selectedUser: {},
  search: '',
  searchResult: []
}

export default {
  namespace: 'store/',
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
